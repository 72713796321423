import React from 'react';
import { HSFacility, HSPatient } from 'server-openapi';
import { format } from 'date-fns';
import { DateUtils } from '../../../core/utils/dateUtils';
import { Layout } from '../../../kit/Layout';
import { Text } from '../../../kit/Text';
import styled, { useTheme } from 'styled-components';
import { Image } from '../../../kit/Image';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';

interface IProps {
  patient: HSPatient;
  facility: HSFacility;
  light?: boolean;
  extraTitle?: string;
}

export function PatientCompactInfo(props: IProps) {
  const theme = useTheme();
  const patientUtils = useApiUtils().patients;
  return (
    <Layout horizontal gap={0.5}>
      {props.patient.imageUrl && (
        <PatientImage src={props.patient.imageUrl} facilityGroupId={props.facility.facilityGroupId!} />
      )}
      <Layout>
        <h2 style={{ color: props.light ? theme.backgrounds.default.fg : theme.backgrounds.default.bg }}>
          {patientUtils.getDisplayPatientName(props.patient)}
          {props.extraTitle ?? ""}
        </h2>
        <h4 style={{ color: props.light ? theme.backgrounds.default.fg : theme.backgrounds.default.bg }}>
          <Text weight={'200'}>
            Room {props.patient.roomNumber}, {props.facility.name} &bull; DOB:{' '}
            {props.patient.dateOfBirth && format(DateUtils.toOffsetlessDate(props.patient.dateOfBirth), 'dd MMM yyyy')}{' '}
            &bull; MPS ID: {props.patient.hsId}
          </Text>
        </h4>
      </Layout>
    </Layout>
  );
}

const PatientImage = styled(Image)`
  border-radius: 50%;
  max-width: 70px;
  height: 70px;
`;
