import { format } from 'date-fns';
import React from 'react';
import { DateUtils } from '../../core/utils/dateUtils';
import { FormInput, formInput } from './FormInput';
import { StyledTextInput, StyledTextInputProps } from './TextInput';

interface Props extends FormInput<Date | undefined>, StyledTextInputProps {
  dateTime?: boolean;
}

export const DateInput = formInput(function (props: Props) {
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange?.(event, DateUtils.toDate(event.currentTarget.value));
  };

  return (
    <StyledTextInput
      type={props.dateTime ? 'datetime-local' : 'date'}
      ref={props.inputRef}
      name={props.name}
      placeholder={props.placeholder}
      value={
        props.value && !isNaN(props.value.getTime())
          ? format(props.value, props.dateTime ? "yyyy-MM-dd'T'HH:mm" : 'yyyy-MM-dd')
          : ''
      }
      onChange={onChange}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
    />
  );
});
