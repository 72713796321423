import React from 'react';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { format } from 'date-fns';
import * as lodash from 'lodash';
import { MedisphereTestResult } from '../../../../syncstream/SyncTestResults';

interface IProps {
  testResults: Array<MedisphereTestResult>;
  testResultType: string;
  //retroactive fix to ensure all graph points have the same key
  graphDataKey: Array<string | undefined>;
}

const GRAPH_LINE_COLORS = ['#4E2974', '#4BE2C5', '#FF89BE', '#8A06EE'];

interface IGraphItem {
  timeOfTest: string;
  [key: string]: number | string; //dynamic key value pair to accommodate multiple fields
}

export function TestResultGraph(props: IProps) {
  const seriesGroups = lodash.groupBy(
    props.testResults
      //remove any results without test entries (prevents undefined issue)
      .filter((testResult) => testResult.testResultEntries && testResult.testResultEntries.length > 0)
      //sort by timestamp before keeping the last 10 results
      .sort((a, b) => a.timeStamp?.localeCompare(b.timeStamp ?? '') ?? 0)
      //keep only the last 10 results
      .slice(-10),
    (r) => r.timeStamp,
  );
  const graphData: Array<IGraphItem> = Object.keys(seriesGroups)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => {
      const graphItem: IGraphItem = {
        timeOfTest: format(DateUtils.toDate(key), 'H:mm d MMM'),
      };
      for (const group of seriesGroups[key].values()) {
        let index = 0; //because blood pressure has 2 keys for one entry
        for (const entry of group.testResultEntries!) {
          const key = props.graphDataKey?.[index] ?? entry.key!;
          graphItem[key] = parseFloat(entry.value!);
          index += 1;
        }
      }
      return graphItem;
    });

  return (
    <GraphContainer padding={'2em'}>
      <Text weight={'bold'}>Recent {props.testResultType.toLocaleLowerCase()} test results</Text>
      <CustomResponsiveContainer width="99%" aspect={4}>
        {!graphData[0] ? (
          <Text>No historical Data</Text>
        ) : (
          <LineChart width={500} height={400} data={graphData}>
            {/* TODO: address the unit scale on this axis, the time jumps around from point to point and the gaps in between aren't equal */}
            <XAxis
              tick={<CustomizedAxisTick {...props} x={0} y={0} />}
              padding={{ left: 20, right: 20 }}
              tickLine={false}
              fontSize={'5px'}
              dataKey="timeOfTest"
              allowDuplicatedCategory={false}
              interval={0}
            />
            <YAxis domain={[0, 'dataMax']} tick={{ transform: 'translate(0, 10)' }} tickLine={false} />
            <Legend verticalAlign="top" height={50} />
            <Tooltip />

            {Object.keys(graphData[0])
              .filter((key) => key !== 'timeOfTest')
              .map((key, index) => {
                return (
                  <Line
                    isAnimationActive={false}
                    name={key}
                    key={key}
                    type="monotone"
                    dataKey={key}
                    stroke={GRAPH_LINE_COLORS[index % GRAPH_LINE_COLORS.length]}
                    dot={{ stroke: GRAPH_LINE_COLORS[index], strokeWidth: 5 }}
                  />
                );
              })}
          </LineChart>
        )}
      </CustomResponsiveContainer>
    </GraphContainer>
  );
}

function CustomizedAxisTick(props: any) {
  const { x, y, payload } = props;
  const splitLabel: string[] = (payload.value as string).split(' ');

  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="middle" fill="#666">
        <tspan x="0" dy="1.2em">
          {splitLabel[0]}
        </tspan>
        <tspan x="0" dy="1.2em">{`${splitLabel[1]} ${splitLabel[2]}`}</tspan>
      </text>
    </g>
  );
}

const CustomResponsiveContainer = styled(ResponsiveContainer)`
  font-size: 0.7rem;
`;

const GraphContainer = styled(Layout)`
  background-color: ${(p) => p.theme.backgrounds.lighter.bg};
  color: ${(p) => p.theme.backgrounds.lighter.fg};
  margin-top: 2%;
  font-size: 0.875rem;
`;
