/* eslint-disable max-lines */
import React from 'react';
import {
  AdHocReasonCode,
  HSDoseRound,
  HSDrug,
  HSPackedMedication,
  HSPackedPrnMedication,
  HSPatient,
  MedicationType,
  ReasonCode,
  SyringeDriverActivityKind,
} from 'server-openapi';
import styled, { css } from 'styled-components';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Text } from '../../../../kit/Text';
import * as datefns from 'date-fns';
import { Layout } from '../../../../kit/Layout';
import { DrugLabel } from '../medicationInformation/DrugDetails';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { Grid } from '../../../../kit/Grid';
import { DrugUtils, IAdHocAdministeredDrug, IAdministeredDrug } from '../../../../syncstream/utils/DrugUtils';
import { CommentsCompact } from './CommentsCompact';
import { OutcomesCompact } from './OutcomesCompact';
import { Button } from '../../../../kit/Button';
import itiriri from 'itiriri';
import { StatusIcon } from './StatusIcon';
import { DrugOutcomeDialog } from '../medicationInformation/DrugOutcomeDialog';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { PatchLocations } from '../patches/PatchLocations';
import { DrugAdministrationUtils } from '../MedicationListsTabbedRouter/DrugAdministrationUtils';
import { SecondCheckDetailsBar } from '../medicationInformation/SecondCheckDetailsBar';
import { PatchUtils } from '../patches/PatchUtils';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { TestResultsCompact } from './TestResultsCompact';
import { PatchObservationCompact } from './PatchObservationCompact';
import { format } from 'date-fns';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

interface IProps {
  drug: HSDrug;
  patient: HSPatient;
  isAdHoc: boolean;
  isCeased: boolean;
  medicationType?: MedicationType;
  currentRound?: HSDoseRound;
  facilityGroupId: number;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
}

export function DrugAdministrationHistory(props: IProps) {
  const administeredDrugs = DrugUtils.getDrugAdministrationHistoryForPatient(props.drug, props.patient, props.isAdHoc)
    .filter((ad): ad is IAdministeredDrug | IAdHocAdministeredDrug => !!ad)
    .sort((a, b) => b.administeredAt?.localeCompare(a.administeredAt ?? '') ?? 0);
  const daySet = new Set(
    administeredDrugs
      .map((ad) => {
        return ad.administeredAt ? datefns.format(DateUtils.toDate(ad.administeredAt), 'd MMMM yyyy') : undefined;
      })
      .filter((day): day is string => !!day),
  );
  const isCeased = props.isCeased;
  const dayArr = itiriri(daySet.values()).toArray();
  return (
    <Layout gap={2}>
      {dayArr.map((day) => {
        return (
          <Container key={day} gap={1}>
            <Text weight={'100'}>{day}</Text>
            {administeredDrugs
              .filter(
                (ad) =>
                  !!ad.administeredAt && datefns.format(DateUtils.toDate(ad.administeredAt), 'd MMMM yyyy') === day,
              )
              .map((ad, index, adArr) => {
                if (index === 0) {
                  return (
                    <DrugAdministrationRecord
                      key={ad.clinicalSystemId!}
                      isAdHoc={props.isAdHoc}
                      isPrn={props.medicationType === MedicationType.Prn}
                      isCeased={isCeased}
                      drug={props.drug}
                      ad={ad}
                      patient={props.patient}
                      facilityGroupId={props.facilityGroupId}
                      packedMedication={props.packedMedication}
                    />
                  );
                }
                return (
                  <DrugAdministrationRecord
                    key={ad.clinicalSystemId!}
                    isAdHoc={props.isAdHoc}
                    isPrn={props.medicationType === MedicationType.Prn}
                    isCeased={isCeased}
                    drug={props.drug}
                    ad={ad}
                    patient={props.patient}
                    facilityGroupId={props.facilityGroupId}
                    packedMedication={props.packedMedication}
                    prevAdministrationTime={adArr[index - 1].administeredAt}
                  />
                );
              })}
          </Container>
        );
      })}
    </Layout>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
export function DrugAdministrationRecord(props: {
  isAdHoc: boolean;
  isPrn: boolean;
  isCeased: boolean;
  drug?: HSDrug;
  ad: IAdministeredDrug | IAdHocAdministeredDrug;
  patient: HSPatient;
  facilityGroupId: number;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
  prevAdministrationTime?: string;
}) {
  const ad = props.ad;
  // TODO: Remove this once SS enables support for AdHocReasonCodes
  if ('administeredAdHocDrugComments' in ad) {
    ad.reasonCode = AdHocReasonCode.Dosed;
  }
  const packedMedication =
    ad.doseTimestamp && ad.medicationId
      ? useApiUtils().patients.getPackedMedicationFromAdministeredDrug(ad.doseTimestamp, ad.medicationId)
      : undefined;

  const chartItem = packedMedication ? useApiUtils().patients.getHSChartItemFromPackedMedication(props.patient, packedMedication) : undefined;
  const isPsychotropicConsent = chartItem?.psychotropicConsent ? chartItem.psychotropicConsent : false;
  const showOutcomes = props.isAdHoc || props.isPrn;
  const services = useSyncCenter();
  const patchObservationStore = useStore(services.patchObservations.store).store;
  //make sure to filter observations created after the administration time
  // this is to separate observations for a reapplied patch that might appear as a new administered drug
  const patchObservations =
    packedMedication && PatchUtils.isPatch(props.drug)
      ? PatchUtils.getPatchObservationInfo(patchObservationStore, packedMedication.hsId!).filter((observation) => {
          return (
            datefns.isAfter(DateUtils.toDate(observation.createdAt), DateUtils.toDate(ad.administeredAt!)) &&
            //to ensure that observation records are shown only for that specific administration duration
            (!props.prevAdministrationTime ||
              datefns.isBefore(DateUtils.toDate(observation.createdAt), DateUtils.toDate(props.prevAdministrationTime)))
          );
        })
      : [];
  const user = useCurrentUser();
  const [visible, setVisible] = React.useState(false);
  const residentDetailUtils = useApiUtils().residentDetails;
  const testResultsForAdministeredDrugs = residentDetailUtils.getTestResultsForAdministeredDrug(ad);
  const userUtils = useApiUtils().users;
  const userString = userUtils.getUserFullNameAndRoleFromUserOrEmailId(ad.confirmationInitials, ad.confirmationUserId) ?? ad.confirmationInitials;
  const groupPermissions = useGroupPermissions();
  const canCreateTestResults = groupPermissions.canRecordVitals;
  const canAdministerPRNAndRecordItsOutcome = groupPermissions.canAdministerPRNAndRecordItsOutcome;
  const syringeDriverActivityStore = useStore(services.syringeDriverActivity.store).store;

  // Should be undefined for non-syringes
  const syringeDriverStartActivity = itiriri(syringeDriverActivityStore.values())
    .filter(
      (activity) =>
        activity.administeredDrugId === props.ad.hsId ||
        activity.administeredDrugClinicalSystemId === (props.ad.clinicalSystemId ?? false),
    )
    .find((activity) => activity.kind === SyringeDriverActivityKind.Start);

  return (
    <Container gap={0}>
      <InformationBox padding={'2rem'} horizontal gap={2}>
        <StatusIconContainer reasonCode={ad.reasonCode}>
          <StatusIcon reasonCode={ad.reasonCode} />
        </StatusIconContainer>
        <Layout gap={1} style={{ width: '100%' }}>
          <DrugDetailContainer alignItemsCenter cols={3} colsTemplate={'3fr 1fr 1.5fr'}>
            <Layout>
              {props.drug && <DrugLabel drug={props.drug} isCeased={props.isCeased} isPsychotropicConsent={isPsychotropicConsent} />}
              <Text>
                {getPatchLocationText(props.drug!, ad as IAdministeredDrug)}
                {syringeDriverStartActivity && `Applied to ${syringeDriverStartActivity.sitePosition} at `}
                {`${
                  !!ad.administeredAt &&
                  `${format(DateUtils.toDate(ad.administeredAt), 'd MMM y')} at ${format(
                    DateUtils.toDate(ad.administeredAt),
                    'HH:mm',
                  )}`
                } by ${userUtils.getUserFullNameAndRoleFromSubjectId(ad.administeredBySubjectId)}`}
              </Text>
            </Layout>
            <SquareBox>{ad.administeredDosage}</SquareBox>
            <ActionsLayout horizontal gap={0.5}>
              <Text style={{ textTransform: 'uppercase', justifySelf: 'center' }}>
                {residentDetailUtils.reasonCodeToString(ad.reasonCode)}
              </Text>
            </ActionsLayout>
          </DrugDetailContainer>
          <Layout gap={0.5}>
            {testResultsForAdministeredDrugs.length > 0 && (
              <TestResultsCompact testResults={testResultsForAdministeredDrugs} />
            )}
            {patchObservations.length > 0 && <PatchObservationCompact observationInfoArr={patchObservations} />}
            {ad.comments.map((comment, index) => {
              return <CommentsCompact facilityGroupId={props.facilityGroupId} comment={comment} key={index} />;
            })}
            {showOutcomes &&
              (ad.outcomes.length ?? -1 > 0 ? (
                ad.outcomes.map((outcome, index) => {
                  return <OutcomesCompact outcome={outcome} key={index} />;
                })
              ) : (
                <OutcomesContainer cols={2} colsTemplate={'4.5fr 1fr'} alignItemsCenter>
                  <Text size={'small'}>No outcome added</Text>
                  <Button onClick={() => setVisible(true)} fullWidth>
                    Outcome
                  </Button>
                </OutcomesContainer>
              ))}
            <DrugOutcomeDialog
              patient={props.patient}
              packedMedication={props.packedMedication}
              drug={props.drug}
              administeredDrugClinicalSystemId={ad.clinicalSystemId!}
              isVisible={visible}
              onClose={() => setVisible(false)}
              onAddOutcome={async (outcome, administeredDrugClinicalSystemId) => {
                if (!canAdministerPRNAndRecordItsOutcome) {
                  toasts.error('You do not have permission to record PRN outcomes');
                  return;
                }
                if (outcome.testResults.length > 0 && !canCreateTestResults) {
                  toasts.error('You do not have permission to record vitals');
                  return;
                }
                await DrugAdministrationUtils.addOutcome(
                  outcome,
                  administeredDrugClinicalSystemId,
                  ad.doseRoundClinicalSystemId,
                  services,
                  user.profile.sub,
                  props.patient,
                  props.facilityGroupId,
                  props.isAdHoc,
                );
                setVisible(false);
              }}
              isAdHoc={props.isAdHoc}
            />
          </Layout>
        </Layout>
      </InformationBox>
      {userString && (
        <SecondCheckDetailsBar
          userName={userString}
          date={props.ad.administeredAt ? DateUtils.toDate(props.ad.administeredAt) : undefined}
        />
      )}
    </Container>
  );
}

function getPatchLocationText(drug: HSDrug, adminiteredDrug: IAdministeredDrug) {
  if (PatchUtils.isPatch(drug)) {
    const patchLocationNumber = adminiteredDrug.patchLocationNumber;

    if (patchLocationNumber) {
      return `Applied to ${PatchLocations.find(
        (pl) => pl.LocationNumber === patchLocationNumber,
      )?.PatchArea.toLowerCase()} on `;
    }
  }

  return '';
}

const Container = styled(Layout)`
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const InformationBox = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  color: black;
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
`;

const DrugDetailContainer = styled(Grid)`
  width: 100%;
`;

const ActionsLayout = styled(Layout)`
  display: flex;
  align-items: center;
  justify-self: center;
`;

const SquareBox = styled.div`
  justify-self: flex-end;
  line-height: 1.25;
  text-align: center;
  padding: 0.625rem;
  border-color: black;
  border-width: 2px;
  width: 3rem;
  margin-right: 0.5rem;
  border-style: solid;
`;

const OutcomesContainer = styled(Grid)`
  justify-content: space-between;
  padding: 0.875rem;
  border-width: 1px;
  border-style: groove;
`;

export const StatusIconContainer = styled.div<{
  reasonCode?: ReasonCode | AdHocReasonCode;
}>`
  font-size: 0.7em;
  width: 30px;
  height: 30px;
  border-radius: 9999999rem;
  border: solid 1px rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.typography.defaults.color};

  ${(props) => {
    switch (props.reasonCode) {
      case AdHocReasonCode.Dosed:
      case ReasonCode.Dosed:
      case ReasonCode.SelfAdministered:
        return css`
          background-color: #a4e92a;
        `;
    case ReasonCode.DosedLate:
      return css`
          background-color: #262626;
          color: #00ff00;
        `;
      case AdHocReasonCode.Refused:
      case ReasonCode.Refused:
        return css`
          background-color: #fc4b00;
        `;
    case AdHocReasonCode.Other:
    case ReasonCode.Other:
      return css`
          border-color: #aaaaaa;
          background-color: #ffffff;
        `;
      default:
        return css`
          background-color: #fff752;
        `;
    }
  }}
`;
