/* eslint-disable max-lines */
import React from 'react';
import { HSChartDose, HSDrug, NimAvailableDrugDto } from 'server-openapi';
import styled from 'styled-components';
import { Order } from '../../../../common/Order';
import { Grid } from '../../../../kit/Grid';
import { Image } from '../../../../kit/Image';
import { Text } from '../../../../kit/Text';
import { AdministrationWarnings } from '../../../../syncstream/utils/DrugUtils';
import { OrderNotesProps } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderNotesDialog';
import { OrderResupplyProps } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderResupplyDialog';
import { PatchInfo } from '../MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import { PatchUtils } from '../patches/PatchUtils';
import { AdministrationWarningIcons, DrugLabel } from './DrugDetails';

export interface StatusLabelInfo {
  label: string;
  colour: string;
  backgroundColour: string;
}

export interface MedicationInfoSectionProps {
  facilityGroupId: number;
  drug?: HSDrug;
  route?: string;
  directions?: string;
  administrationWarnings: AdministrationWarnings;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;
  findRecentOrder?: (hsPatientId: number, hsDrugCode: string) => Order | undefined;
  nimAvailableDrug?: NimAvailableDrugDto;
  patchInfo?: PatchInfo;
  chartItemDose?: HSChartDose;
  isTimeCritical?: boolean;
  isPsychotropicConsent?: boolean;
  statusLabel?: StatusLabelInfo;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function MedicationInfoSection(props: MedicationInfoSectionProps) {
  const { patchInfo, drug, facilityGroupId, administrationWarnings, nimAvailableDrug, chartItemDose, isTimeCritical } =
    props;
  const route = props.route ?? props.nimAvailableDrug?.route;
  return (
    <Grid cols={2} colsTemplate={'0.5fr 1fr'} gap={1}>
      <DrugInfoContainer>
        {props.statusLabel && (
          <StatusLabel style={{ color: props.statusLabel.colour, background: props.statusLabel.backgroundColour }}>
            {props.statusLabel.label}
          </StatusLabel>
        )}
        {drug?.imageUri && <DrugImage src={drug.imageUri} facilityGroupId={facilityGroupId} />}
      </DrugInfoContainer>

      <DrugInfoContainer>
        {drug && (
          <DrugLabel drug={drug} isCeased={administrationWarnings.isCeasedMedication} isTimeCritical={isTimeCritical} isPsychotropicConsent={props.isPsychotropicConsent!} />
        )}
        <Text>{drug?.genericName && `(${drug.genericName})`}</Text>
        {nimAvailableDrug ? (
          <Text>{`Indications: ${nimAvailableDrug.indications} ${nimAvailableDrug.route}`}</Text>
        ) : (
          <Text>
            {props.directions} {route && `[${route}]`}
          </Text>
        )}
        {patchInfo && patchInfo.PatchStatus ? (
          <PatchInfoSection patchInfo={patchInfo} drug={drug} />
        ) : (
          <>
            {chartItemDose?.reasonForPrescribing && (
              <Text>Reason for prescribing: {chartItemDose?.reasonForPrescribing}</Text>
            )}
            {chartItemDose?.indications && <Text>Indications: {chartItemDose?.indications}</Text>}
            <WarningContainer>
              <AdministrationWarningIcons administrationWarnings={administrationWarnings} />
            </WarningContainer>
          </>
        )}
      </DrugInfoContainer>
    </Grid>
  );
}

function PatchInfoSection(props: { patchInfo: PatchInfo; drug?: HSDrug }) {
  const { patchInfo, drug } = props;

  const isPatchRemoval = PatchUtils.isPatchRemoval(drug!);

  if (isPatchRemoval) {
    return <></>;
  }

  return (
    <>
      {patchInfo.TimeRemaining && <Text>Patch time remaining: {patchInfo.TimeRemaining}</Text>}
      <Text>
        {patchInfo.ActionedDate && <>{patchInfo.ActionedDate}</>}
        {patchInfo.ActionedBy && <> by {patchInfo.ActionedBy}</>}
      </Text>
    </>
  );
}

const DrugImage = styled(Image)`
  object-fit: cover;
  max-width: 100%;
  height: auto;
`;

const DrugInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-wrap: break-word;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.2em;
  padding: 0.2em;
`;

const StatusLabel = styled.div`
  text-align: center;
  padding: 0.2em;
  margin-bottom: 0.5em;
`;
