import React, {useEffect, useState} from 'react';
import {ArrayField, UseFormMethods, FieldError} from 'react-hook-form';
import {RoleDto} from 'server-openapi';
import {DateInput} from '../../../../kit/Forms/DateInput';
import {FormGroup} from '../../../../kit/Forms/FormGroup';
import {SelectOption, Select} from '../../../../kit/Forms/Select';
import {Grid} from '../../../../kit/Grid';
import {RoleAccessDto, UserDetailsFormValues} from '../UserDetailsPage';
import {Button} from "../../../../kit/Button";
import styled from "styled-components";
import {Dialog} from "../../../../kit/Dialog";
import {Text} from "../../../../kit/Text";
import {Layout} from "../../../../kit/Layout";
import {colors} from "../../../../kit/Theme/Theme";
import {toasts} from "../../../../kit/Toasts/Toaster";

interface IProps {
  field: Partial<ArrayField<RoleAccessDto, 'id'>>;
  index: number;
  form: UseFormMethods<UserDetailsFormValues>;
  roleOptions: SelectOption<RoleDto>[];
  disabled?: boolean;
  roleDisabled?: boolean;
}

export const UserRolesDetails = (props: IProps) => {
  const {field, index, form, roleOptions, disabled, roleDisabled} = props;
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const [isCreatedWithinThirtyMinutesAgo, setIsCreatedWithinThirtyMinutesAgo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const checkStartDate = () => {
    const thirtyMinutesAgo = Date.now() - (1000 * 30 * 60);

    if (field.startDate && field.startDate.getTime() < thirtyMinutesAgo) {
      setIsCreatedWithinThirtyMinutesAgo(true);
    }
  }

  const checkExpiryDate = () => {
    const todayDate = new Date();

    if (field.expiryDate && field.expiryDate < todayDate && (todayDate.getTime() - field.expiryDate.getTime()) > 1800000) { // 1800000 is half an hour in milliseconds
      setIsDisabled(true);
    }
  }

  const validateStartDate = (startDate: Date | undefined) => {
    const startTime = startDate?.getTime();
    const todayDate = new Date();
    const todayTime = todayDate.getTime();
    // when adding new role, start time is automatically set to todayDate/Time () hence causing validation error. Here we give 10 minutes window for an error-free role assignment
    const tenMinutesAgo = Date.now() - (1000 * 10 * 60);

    if (!startDate) {
      return 'Start date is required';
    }
    if (startTime && startDate && startDate < todayDate && startTime < todayTime && startTime < tenMinutesAgo && props.field.isNew) { // it only validates the newly added role. Old roles/start dates won't be validated.
      return 'Start date cannot be earlier than today\'s date';
    }
  };

  const validateExpiryDate = (expiryDate: Date | undefined) => {
    const todayDate = new Date();
    const todayTime = todayDate.getTime();
    const currentExpiryDate = form.getValues().roleAccess[index].expiryDate;
    const currentExpiryTime = currentExpiryDate ? currentExpiryDate.getTime() : null;
    const expiryTime = expiryDate ? expiryDate?.getTime() : null;
    const startDate = form.getValues().roleAccess[index].startDate;
    const startTime = form.getValues().roleAccess[index].startDate ? form.getValues().roleAccess[index].startDate.getTime() : undefined;

    if (expiryTime && expiryDate && startTime && startDate && expiryDate < startDate && expiryTime < startTime) {
      return 'Expiry date cannot be earlier than start date';
    }
    if (!props.field.isNew && expiryDate && expiryDate !== currentExpiryDate && expiryDate < todayDate && !isDisabled) { // it is for old and currently null expiry dates. Old expiry dates with value are already read only (greyed out).
      return 'Expiry date cannot be earlier than today\'s date';
    }
    if (expiryDate && expiryTime && todayDate && todayTime && expiryDate < todayDate && props.field.isNew) { // it only validates the newly added role. Old roles/expiry dates won't be validated.
      return 'Expiry date cannot be earlier than today\'s date';
    }
    if (startTime && startDate && expiryTime && expiryDate && startDate > expiryDate && startTime > expiryTime) {
      return 'Start date cannot be later than expiry date';
    }
  }

  const revokePermission = () => {
    form.setValue(`roleAccess.${index}.expiryDate`, new Date());
    setOpenRevokeDialog(false);
    toasts.warning("Revoke date has been successfully set. Click on Save button to save your changes");
  }

  useEffect(() => {
    checkStartDate();
    checkExpiryDate();
  }, []);



  return (
    <Grid colsTemplate="2fr 1fr 1fr .5fr">
      <FormGroup label="Title" fullWidth
                 error={(form.errors.roleAccess?.[index]?.role as FieldError)?.message}>
        <Select
          fullWidth
          name={`roleAccess.${index}.role`}
          options={roleOptions}
          disabled={roleDisabled || disabled || isCreatedWithinThirtyMinutesAgo}
          value={
            roleOptions.find((role) => role.value.urn === field.role?.urn)?.value ?? {'No RoleOption for field.role?.urn': 'No RoleOption for field.role?.urn'}
          }
          rules={{
            validate: (role: RoleDto) => {
              if (role === undefined || Object.keys(role).length <= 1) {
                return 'The role field is required';
              }
            },
          }}
        />
      </FormGroup>
      <FormGroup label="Start Date" isRequired fullWidth error={form.errors.roleAccess?.[index]?.startDate?.message}>
        <DateInput
          dateTime
          name={`roleAccess.${index}.startDate`}
          value={field.startDate}
          fullWidth
          rules={{
            validate: (value) => {
              return validateStartDate(value);
            }
          }}
          required
          disabled={disabled || isCreatedWithinThirtyMinutesAgo}
        />
      </FormGroup>
      <FormGroup label="Expiry Date" fullWidth error={form.errors.roleAccess?.[index]?.expiryDate?.message}>
        <DateInput
          dateTime
          name={`roleAccess.${index}.expiryDate`}
          value={field.expiryDate ?? undefined}
          fullWidth
          rules={{
            validate: (value) => {
              return validateExpiryDate(value);
            }
          }}
          disabled={disabled || isDisabled}
        />
      </FormGroup>
      <div>
        <ButtonContainer>
          <Button type="button" onClick={() => {
            setOpenRevokeDialog(true)
          }} fullWidth>Revoke</Button>
        </ButtonContainer>
      </div>
      {/* Revoke Dialog */}
      <Dialog open={openRevokeDialog} onRequestClose={() => setOpenRevokeDialog(false)}
              closeButtonColor={colors.bg_green_400}>
        <Layout padding={0.5} gap={1}>
          <Text style={{marginBottom: "1em"}}>Are you sure you want to revoke this role?</Text>
          <Layout horizontal gap={0.5} style={{justifyContent: 'flex-end', width: '40em'}}>
            <Button onClick={() => revokePermission()} style={{width: '200px'}}>
              Yes
            </Button>
            <Button onClick={() => setOpenRevokeDialog(false)} style={{width: '200px'}}>
              Cancel
            </Button>
          </Layout>
        </Layout>
      </Dialog>
    </Grid>
  );
}

const ButtonContainer = styled.div`
    margin: 24px;
`
