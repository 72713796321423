import { Paper } from '../../../kit/Paper';
import { Text } from '../../../kit/Text';
import { DateUtils } from '../../../core/utils/dateUtils';
import { Layout } from '../../../kit/Layout';
import React from 'react';
import styled from 'styled-components';
import { Image } from '../../../kit/Image';
import { DrugAdministrationRecord } from '../../ResidentDetails/components/drugDetails/DrugAdministrationHistory';
import { MedicationDoses, MedicationListType } from '../MedicationListPage/MedicationListLayout';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import {HSPatient} from "server-openapi";
import {useHistory} from "react-router";

export function AdministrationCard(props: MedicationDoses) {
  const utils = useApiUtils();
  const history = useHistory();
  const facilityGroupId = utils.patients.findFacilityGroup(props.patient);
  function navigate(patient: HSPatient) {
    history.push(`/resident-details/${patient.hsId}/`)
  }

  return (
    <Paper>
      <InformationBox horizontal gap={1}>
        <DoseTimestampColumn>
          <Text weight={'bold'}>{DateUtils.dateTo24HourTimeString(DateUtils.toDate(props.ad.administeredAt!))}</Text>
        </DoseTimestampColumn>
        <PatientImageColumn style={{ cursor: 'pointer' }} onClick={() => navigate(props.patient)}>
          {props.patient.imageUrl && <PatientImage src={props.patient.imageUrl} facilityGroupId={facilityGroupId} />}
        </PatientImageColumn>
        <PatientDetailColumn>
          <Text weight={'bold'}>{utils.patients.getDisplayPatientName(props.patient)}</Text>
          <Text>
            {props.facility?.name} Rm {props.patient.roomNumber}
          </Text>
        </PatientDetailColumn>
        <DrugAdministrationRecord
          isCeased={props.isCeased}
          isAdHoc={props.medicationListType === MedicationListType.Nims}
          isPrn={props.medicationListType === MedicationListType.Prns}
          drug={props.drug}
          ad={props.ad}
          patient={props.patient}
          facilityGroupId={facilityGroupId}
        />
      </InformationBox>
    </Paper>
  );
}

const PatientImage = styled(Image)`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 999999999px;
`;

const InformationBox = styled(Layout)`
  display: flex;
  align-items: center;
`;

const PatientImageColumn = styled(Layout)`
  padding-right: 1.25rem;
`;

const PatientDetailColumn = styled(Layout)`
  padding-right: 1.25rem;
  flex-basis: 15%;
`;

const DoseTimestampColumn = styled(Layout)`
  padding-right: 1.25rem;
  flex-basis: 5rem;
`;
